import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Chip } from '@mui/material';
// routes 
// hooks
import useAuth from '../hooks/useAuth';
import useIsMountedRef from '../hooks/useIsMountedRef';
// components
import MenuPopover from '../components/MenuPopover';
import { IconButtonAnimate } from '../components/animate';
import Iconify from '../components/Iconify';
//
import PinCodeModal from './PinCodeModal';
import { fRemainD } from '../utils/formatNumber';

// ----------------------------------------------------------------------

const MENU_OPTIONS_ADMIN = [{
    label: 'menu.home',
    linkTo: '/',
},
{
    label: 'menu.rent',
    linkTo: '/car-rent',
},
{
    label: 'menu.user_management',
    linkTo: '/admin/user-manage'
},
{
    label: 'menu.device_manage',
    linkTo: '/admin/device-manage'
},
{
    label: 'menu.device_dashboard',
    linkTo: '/admin/device-dashboard'
},
{
    label: 'menu.order',
    linkTo: '/admin/orders'
},
    ,
{
    label: 'menu.transactions',
    linkTo: '/admin/transactions'
},
{
    label: 'menu.withdraws',
    linkTo: '/admin/withdraw-request'
},
{
    label: 'menu.rentcars',
    linkTo: '/admin/rent-car-status'
},
];
const MENU_OPTIONS = [
    {
        label: 'menu.home',
        linkTo: '/',
    },
    {
        label: 'menu.rent',
        linkTo: '/car-rent',
    },

];

// ----------------------------------------------------------------------

export default function SettingPopover() {
    const navigate = useNavigate();
    const [menuOptions, setMenuOptions] = useState(MENU_OPTIONS);
    const { user, logout } = useAuth();
    const { t } = useTranslation();
    const isMountedRef = useIsMountedRef();

    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(null);
    const [pinModal, setPinModal] = useState(false);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handlePinModalOpen = () => {
        setPinModal(true);
        handleClose()
    }

    const handlePinModalClose = () => {
        setPinModal(false);
    }

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/', { replace: true });

            if (isMountedRef.current) {
                handleClose();
            }
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Unable to logout!', { variant: 'error' });
        }
    };
    useEffect(() => {
        if (user) {
            if (user.role === "admin") {
                setMenuOptions(MENU_OPTIONS_ADMIN);
            }
        }
    }, []);
    return (
        <>
            <IconButtonAnimate onClick={handleOpen}
                sx={
                    {
                        p: 0,
                        ...(open && {
                            '&:before': {
                                zIndex: 1,
                                content: "''",
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                position: 'absolute',
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.1),
                            },
                        }),
                    }
                } >
                <Iconify icon={'eva:people-fill'}
                    width={
                        { sx: 20, md: 30 }}
                    height={
                        { sx: 20, md: 30 }}
                /> </IconButtonAnimate>


            <MenuPopover open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={
                    {
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        pb:0.75,
                        '& .MuiMenuItem-root': {
                            typography: 'body2',
                            borderRadius: 0.75,
                            lineHeight: 1,
                        },
                    }
                } >
                <Box sx={
                    { my: 1.5, px: 2.5 }} >
                    <Typography variant="subtitle2"
                        noWrap > {user?.phoneNumber}
                    </Typography>
                    <Chip label={user?.status} color='success'
                        size="small" />

                    {(user.remainDays && ((user.remainDays) > 0)) ? <Chip color='warning' label={`${fRemainD(user?.remainDays).text}`} sx={{ ml: 1 }}
                        size="small" /> : ""}

                </Box>

                <Divider sx={
                    { borderStyle: 'dashed' }}
                />

                <Stack sx={{ p: 1 }} >
                    {
                        menuOptions.map((option) => (
                            <MenuItem key={option.label}
                                to={option.linkTo}
                                component={RouterLink}
                                onClick={handleClose} sx={{ minHeight: { xs: 24 } }}>
                                {t(option.label)}

                            </MenuItem>
                        ))

                    }
                </Stack>

                <Divider sx={
                    { borderStyle: 'dashed', mb:1 }}
                />
                <MenuItem
                    sx={{ minHeight: { xs: 24 } , mx:1}}

                    onClick={() => navigate('/driver-profile')}

                >
                    {t('menu.profile')}
                </MenuItem>
                <MenuItem
                    sx={{ minHeight: { xs: 24 }, mx:1 }}

                    onClick={() => navigate('/device-register')}

                >
                    {t('menu.register')}
                </MenuItem>
                <MenuItem
                    sx={{ minHeight: { xs: 24 }, mx:1 }}

                    onClick={() => navigate('/license-profile')}

                >
                    {t('menu.device')}
                </MenuItem>
                <MenuItem
                    sx={{ minHeight: { xs: 24 }, mx:1 }}

                    onClick={handlePinModalOpen}
                >
                    {t('menu.nickname')}
                </MenuItem>
                <MenuItem
                    sx={{ minHeight: { xs: 24 }, mx:1 }}
                    key={"time-command"}
                    to={'/time-command'}
                    component={RouterLink}
                    onClick={handleClose}

                >
                    {t('menu.time')}
                </MenuItem>
                {/* <MenuItem
                    sx={{ minHeight: { xs: 24 }, mx:1 }}
                    key={"logManagement"}
                    to={'/log-management'}
                    component={RouterLink}
                    onClick={handleClose}

                >
                    {t('menu.log')}
                </MenuItem> */}
                {/* <MenuItem
                    sx={{ minHeight: { xs: 24 }, mx:1 }}
                    key={"gps"}
                    to={'/log-gps'}
                    component={RouterLink}
                    onClick={handleClose}

                >
                    {t('menu.gps')}
                </MenuItem> */}
                <MenuItem
                    sx={{ minHeight: { xs: 24 }, mx:1 }}
                    key={"licenseLogs"}
                    to={'/log-license'}
                    component={RouterLink}
                    onClick={handleClose}

                >
                    {t('menu.license')}
                </MenuItem>
                <MenuItem
                    sx={{ minHeight: { xs: 24 }, mx:1 }}
                    onClick={() => navigate('/log-map')}
                >
                    {t('menu.mapLog')}
                </MenuItem>
                <MenuItem
                    sx={{ minHeight: { xs: 24 }, mx:1 }}
                    onClick={() => navigate('/log-sim')}
                >
                    {t('menu.simLog')}
                </MenuItem>
                <MenuItem
                    sx={{ minHeight: { xs: 24 }, mx:1 }}
                    onClick={() => navigate('/configure-driver')}
                >
                    {t('menu.driver')}
                </MenuItem>
                <MenuItem
                    sx={{ minHeight: { xs: 24 }, mx:1 }}
                    onClick={() => navigate('/Order')}
                >
                    {t('menu.order')}
                </MenuItem>
                <MenuItem
                    sx={{ minHeight: { xs: 24 }, mx:1 }}
                    onClick={() => navigate('/help')}
                >
                    {t('menu.help')}
                </MenuItem>
                      
                <Divider sx={
                    { borderStyle: 'dashed' }}
                />

                <MenuItem
                    onClick={handleLogout}
                    sx={{ minHeight: { xs: 24 }, mx:1 }}
                >
                    {t('menu.log_out')}
                </MenuItem>
            </MenuPopover>
            <PinCodeModal open={pinModal} onModalClose={handlePinModalClose} phoneNumber={user.phoneNumber} username={user.username} />
        </>
    );
}